import { useState, useEffect } from "react";
import {useLocation} from "react-router-dom";

// Our hook
export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    const reset = () => {clearTimeout(handler)};
    return reset;
  }, [value, delay]);
  return debouncedValue;
}

export const useQueryParams = () => new URLSearchParams(useLocation().search)
