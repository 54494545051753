/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, {useCallback, useEffect, useState} from "react";
import {TextField} from "@material-ui/core";
import {ArrowForward as SettingIcon, LockOpen, ArrowBack as ViewIcon} from "@material-ui/icons";
import {useRouteMatch} from "react-router-dom";
import lists from "../lib/lists";
import {useDebounce, useQueryParams} from "../lib/hooks";
import DraggableList from "../components/DraggableList";
import styles from "./Admin.module.css";
import {Settings} from "../components/Settings";
import {ToggleButton} from "../components/ToggleButton";
import Fab from "@material-ui/core/Fab";

const createListItem = (index, data) => {
  const entry = data.entries[index];
  const component = !data.completed ? (
    <TextField
      className={styles.textField}
      value={entry}
      onChange={e => data.updateEntry(e.target.value, index)}
    />
  ) : (
    <span>{entry}</span>
  );
  return {
    key: index,
    text: entry,
    completed: data.completed,
    released: index < data.released,
    component,
  };
};

const useList = () => {
  const [title, setTitle] = useState("");
  const [entries, setEntries] = useState(Array(10).fill(""));
  const [completed, setCompleted] = useState(false);
  const [released, setReleased] = useState(0);
  const [random, setRandom] = useState(false);
  const [pub, setPublic] = useState(false);
  const { id } = useRouteMatch().params;

  const fetch = useCallback(async () => {
    const data = await lists.get(id);
    setTitle(data.title);
    setEntries(data.entries);
    setCompleted(data.completed);
    setReleased(data.released);
    setRandom(data.random);
    setPublic(data.public);
  }, [id]);

  useEffect(() => {
    fetch();
  }, [id, fetch]);

  const updateEntries = es => {
    setEntries(es);
  };

  const updateEntry = (e, i) => {
    const copy = [...entries];
    copy[i] = e;
    setEntries(copy);
  };

  const debouncedEntries = useDebounce(entries, 1000);
  useEffect(() => {
    if (!entries.every(s => s === "")) {
      lists.setEntries(id, debouncedEntries);
    }
  }, [id, entries, debouncedEntries]);

  return {
    id,
    title,
    entries,
    completed,
    released,
    random,
    public: pub,
    updateEntry,
    updateEntries,
    fetch,
  };
};

const listIsDone = list =>
    list.entries.some(s => s === "") ||
    list.completed ||
    new Set(list.entries).size !== list.entries.length

const LockButton = ({disabled, onClick}) => (
    <Fab color={"secondary"} disabled={disabled} onClick={onClick}>
      <LockOpen />
    </Fab>
)

const SettingsForList = ({list, onSetRandom, onSetPublic, onRelease}) => (
    <Settings
        random={{
          enabled: list.released === 0,
          checked: list.random,
          onChange: onSetRandom
        }}
        publicz={{
          enabled: true,
          checked: list.public,
          onChange: onSetPublic,
        }}
        listId={list.id}
        listSize={list.entries.length}
        releasedCount={list.released}
        onRelease={onRelease}/>
)

const onRelease = list => async i => {
  if (i >= list.entries.length) {
    return;
  }
  if (i >= 8) {
    await lists.setReleased(list.id, 10)
  } else {
    await lists.setReleased(list.id, i);
  }
  await list.fetch();
};

const onSetRandom = list => async () => {
  await lists.setRandom(list.id, !list.random);
  await list.fetch();
};

const onSetPublic = list => async () => {
  await lists.setPublic(list.id, !list.public);
  await list.fetch();
};

const onReadyClick = list => async () => {
  await lists.setCompleted(list.id, true);
  await list.fetch();
};



const Admin = () => {
  const query = useQueryParams()
  const startView = query.has("view") ? query.get("view") : "settings"
  const [view, setView] = useState(startView);
  const list = useList();

  let actionButton = list.completed ?
      <ToggleButton A={<SettingIcon/>} B={<ViewIcon/>} showA={view !== "settings"} onNewValue={showA => setView(showA ? "settings" : "create")}/> :
      <LockButton disabled={listIsDone(list)} onClick={onReadyClick(list)}/>

  const listItems = list.entries.map((entry, index) => createListItem(index, list));
  const content = !list.completed || view === "create" ?
      <DraggableList list={listItems} setList={list.updateEntries}/> :
      <SettingsForList list={list} onRelease={onRelease(list)} onSetPublic={onSetPublic(list)} onSetRandom={onSetRandom(list)}/>
  return (
    <div className={styles.body}>
      <h1 className={styles.title}>{list.title}</h1>
      {content}
      <div className={styles.horizontal_centering}>
        <div className={styles.action}>
          {actionButton}
        </div>
      </div>
    </div>
  );
};

export default Admin;
