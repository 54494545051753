import React from "react";
import GoogleButton from "react-google-button";
import { useHistory } from "react-router-dom";
import styles from "./Login.module.css";
import { loginWithGoogle } from "../lib/firebase";


const useLoginCall = () => {
  const history = useHistory();
  return async () => {
    await loginWithGoogle();
    history.push("/root");
  };
};

function Login() {
  const loggerIn = useLoginCall();
  return (
    <div className={styles.loginCard}>
      <GoogleButton
        className={styles.googleButton}
        type="dark"
        onClick={() => loggerIn().catch(e => console.log(e))}
      />
    </div>
  );
}

export default Login;
