import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import ListPicker from "../components/ListPicker";
import Create from "./Create";
import styles from "./Home.module.css";
import Arrow from "../Arrow";
import Logo from "../Logo";

let timeout;

const Home = ({ history }) => {
  const [display, setDisplay] = useState(null);
  const [curtain, setCurtain] = useState(true);
  const [showInfo, setShowInfo] = useState(false);
  const viewList = ({id}) => {
    history.push(`/${id}`);
  };
  const viewDashboard = ({id}) => {
    history.push(`/${id}/dashboard`);
  };

  useEffect(
    () => () => {
      clearTimeout(timeout);
    },
    []
  );

  const show = displayValue => {
    setShowInfo(false);
    if (display === null) {
      setDisplay(displayValue);
      setCurtain(false);
    } else {
      setCurtain(true);
      timeout = setTimeout(() => {
        setDisplay(displayValue);
        setCurtain(false);
      }, 750);
    }
  };

  let content;
  switch (display) {
    case "visit":
      content = (
        <ListPicker onJoinClick={viewList} onDashboardClick={viewDashboard} />
      );
      break;
    case "create":
      content = <Create />;
      break;
    default:
      content = <></>;
  }
  return (
    <div className={styles.view}>
      <div className={styles.buttons}>
        <Button
          id="visit-list"
          disabled={display === "visit"}
          variant="contained"
          color="secondary"
          onClick={() => show("visit")}
        >
          Consume List
        </Button>
        <Button
          id="create-list"
          disabled={display === "create"}
          variant="contained"
          color="secondary"
          onClick={() => show("create")}
        >
          Compose List
        </Button>
      </div>
      <div className={styles.homecontent}>
        {content}
        <div
          className={styles.curtain}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: curtain === true ? "100%" : "3rem",
            color: "#92140C",
          }}
        >
          <Arrow
            style={{
              transition: "height 0.5s",
              height: curtain ? 0 : 40,
              color: "#fff8f0",
            }}
            onClick={() => {
              setCurtain(true);
              setDisplay(null);
            }}
          />
          {showInfo ? (
            <div
              role="button"
              tabIndex={-1}
              style={{
                margin: 20,
                color: "#fff8f0",
                outline: "none",
              }}
              onClick={() => setShowInfo(false)}
              onKeyDown={() => {}}
            >
              Compose a list of Top 10 in your favourite subject, and share it
              with your friends!
              <br />
              <br />
              Consume a list, either by clicking on a shared link or entering
              the code.
              <br />
              <br />
              You can only see and place one entry at a time, no take-backsies!
              <br />
              When there's only two left, you get both simultaneously.
            </div>
          ) : (
            <div
              role="button"
              tabIndex={-1}
              style={{
                transition: "height 0.3s, width 0.5s",
                height: curtain ? 140 : 0,
                width: curtain ? 140 : 0,
                backgroundColor: "#fff8f0",
                borderRadius: "100%",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center ",
                outline: "none",
              }}
              onClick={() => setShowInfo(true)}
              onKeyDown={() => {}}
            >
              <Logo width={128} height={128} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Home);
