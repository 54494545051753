import React, { useEffect, useState, createRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import styles from "./Player.module.css";
import lists from "../lib/lists";

const InvalidView = () => (
  <div className={styles.invalid}>
    <h1>No data found for this id</h1>
  </div>
);

const LoadingView = () => (
  <div className={styles.loading}>
    <CircularProgress size={70} />
  </div>
);

const initChoosen = Array(10)
  .fill("")
  .map((value, index) => ({ value, index }));

const ListView = ({ id, title, entries, released, whenDone, returnHome }) => {
  const [choosen, setChoosen] = useState(initChoosen);
  const [next, setNext] = useState([""]);
  const [isDone, setIsDone] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [placing, setPlacing] = useState({
    hold: false,
    delta: 0,
    hovered: -1,
    value: "",
  });

  const refs = Array.from({ length: 10 }, () => createRef(null));

  useEffect(() => {
    const persistedData = localStorage.getItem(`answers_${id}`);
    if (persistedData) {
      setChoosen(JSON.parse(persistedData));
    }
    return () => {
      setChoosen(initChoosen);
      setNext([""]);
      setIsDone(false);
      setIsPending(false);
    };
  }, [id]);

  useEffect(() => {
    localStorage.setItem(`answers_${id}`, JSON.stringify(choosen));
    const picked = choosen.filter(i => i.value !== "").length;
    setIsPending(picked >= released);
    switch (picked) {
      case entries.length:
        setIsDone(true);
        break;
      case entries.length - 2:
        setNext([entries[picked], entries[picked + 1]]);
        break;
      case entries.length - 1: {
        const choosenWords = choosen.map(c => c.value);
        const lastEntry = entries.filter(e => !choosenWords.includes(e));
        setNext(lastEntry);
        break;
      }
      default:
        setNext([entries[picked]]);
    }
  }, [id, entries, choosen, released]);

  const moveSuggestion = e => {
    if (!isDragging && !e.changedTouches) {
      return;
    }
    const item = e.changedTouches ? e.changedTouches[0] : e;
    const { height } = item.target.getBoundingClientRect();
    const hovered = refs
      .map(ref => ref.current)
      .findIndex(x => {
        if (x.innerText !== "") {
          return false;
        }
        const y = item.clientY;
        const { top, bottom } = x.getBoundingClientRect();
        return top < y && bottom > y;
      });
    setPlacing({
      hold: true,
      delta: item.clientY - item.target.offsetTop - height / 2,
      hovered,
      value: e.target.innerText,
    });
  };

  const dropSuggestion = () => {
    if (placing.hovered >= 0) {
      const copyChoosen = [...choosen];
      copyChoosen[placing.hovered].value = placing.value;
      setChoosen(copyChoosen);
    }
    setPlacing({ hold: false, delta: 0, hovered: -1, value: "" });
  };

  let doneButton;

  if (JSON.parse(localStorage.getItem(`done_${id}`))) {
    doneButton = (
      <Button
        variant="contained"
        color="primary"
        className={styles.doneButton}
        onClick={returnHome}
      >
        Stats
      </Button>
    );
  } else if (isDone) {
    doneButton = (
      <Button
        variant="contained"
        color="primary"
        className={styles.doneButton}
        onClick={() => whenDone(choosen)}
      >
        Done!
      </Button>
    );
  }

  return (
    <div className={styles.valid}>
      <h1>{title}</h1>
      <div className={styles.list}>
        {choosen.map((s, i) => (
          <>
            <span style={{ alignSelf: "center", justifySelf: "end" }}>
              {i + 1}.
            </span>
            <div
              className={`slot-${s.index}`}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #999",
                backgroundColor:
                  placing.hovered === i ? "#1e1e24" : "rgba(0,0,0,0)",
              }}
              key={s.index}
              ref={refs[i]}
              variant="outlined"
              disabled={s !== "" || isPending}
            >
              {s.value}
            </div>
            <span
              style={{ alignSelf: "center", justifySelf: "end", opacity: "0" }}
            >
              {i + 1}.
            </span>
          </>
        ))}
      </div>
      {doneButton ||
        (!isPending &&
          next.map(s => (
            <div
              className={styles.nextButton}
              key={s}
              role="menuitem"
              tabIndex="0"
              style={{
                transform:
                  placing.value === s && `translateY(${placing.delta}px)`,
                width: 240,
              }}
              onMouseDown={() => setIsDragging(true)}
              onMouseMove={moveSuggestion}
              onMouseUp={e => {
                setIsDragging(false);
                dropSuggestion(e);
              }}
              onTouchMove={moveSuggestion}
              onTouchEnd={dropSuggestion}
            >
              {s}
            </div>
          )))}
    </div>
  );
};

const Player = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { id } = match.params;
  // PENDING, VALID, INVALID
  const [status, setStatus] = useState("PENDING");
  // list values
  const [entries, setEntries] = useState([]);
  const [title, setTitle] = useState("");
  const [released, setReleased] = useState(0);

  useEffect(() => {
    const unsubscribe = lists.listener(id).onSnapshot(res => {
      if (res.exists) {
        const list = res.data();
        setTitle(list.title);
        setReleased(list.released);
        setStatus("VALID");
        if (localStorage.getItem(`entries_${id}`) === null && list.random) {
          const shuffledEntries = list.entries
            .map(value => ({ value, random: Math.random() }))
            .sort((a, b) => a.random - b.random)
            .map(x => x.value);
          localStorage.setItem(
            `entries_${id}`,
            JSON.stringify(shuffledEntries)
          );
          setEntries(shuffledEntries);
        } else if (localStorage.getItem(`entries_${id}`)) {
          setEntries(JSON.parse(localStorage.getItem(`entries_${id}`)));
        } else {
          setEntries(list.entries);
        }
      } else {
        setStatus("INVALID");
      }
    });
    return unsubscribe;
  }, [id]);

  useEffect(() => {
    const unsubscribe = lists.listener(id).onSnapshot(res => {
      if (res.exists) {
        setReleased(res.data().released);
      }
    });
    return unsubscribe;
  });

  const done = result => {
    lists.saveResult(id, result).then(() => {
      localStorage.setItem(`done_${id}`, JSON.stringify(true));
      history.push(`/${id}/dashboard`);
    });
  };

  const returnHome = () => {
    history.push(`/${id}/dashboard`);
  };

  if (entries.length === 0) {
    return <></>;
  }

  switch (status) {
    case "INVALID":
      return <InvalidView />;
    case "PENDING":
      return <LoadingView />;
    default:
      return (
        <ListView
          id={id}
          title={title}
          released={released}
          entries={entries}
          whenDone={done}
          returnHome={returnHome}
        />
      );
  }
};

export default Player;
