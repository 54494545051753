import {Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
  Switch} from "@material-ui/core";
import {Info as InfoIcon, Share as ShareIcon} from "@material-ui/icons";
import React from "react";
import style from "./Settings.module.css";
import {useHistory} from "react-router-dom";
import Typography from "@material-ui/core/Typography";

const RandomReleaseButton = ({disabled, onReleaseAll}) =>  (
    <Button variant="outlined" disabled={disabled} onClick={onReleaseAll}>
        Release All
    </Button>
)

const OrderedReleaseButton = ({released, max, onRelease}) => (
    <Button variant="outlined" disabled={released >= max} onClick={onRelease}>
        Release: {`${released}/${max}`}
    </Button>
)

const SettingsAccordian = ({settings}) => {
  return (
      <div>
        {settings.map(s => (
            <ExpansionPanel key={s.id} disabled={!s.enabled}>
              <ExpansionPanelSummary expandIcon={<InfoIcon />}>
                <FormControlLabel
                    className={style.asd}
                    label={s.title}
                    onClick={event => event.stopPropagation()}
                    onFocus={event => event.stopPropagation()}
                    control={
                      <Switch
                          name={s.id}
                          checked={s.checked}
                          onChange={s.onChange}
                      />
                    }
                />
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  {s.info}
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
        ))}
      </div>
  )
}

const shareUrl = (title, url) => () => {
  if (!navigator.share) return;
  navigator.share({
    url,
    title,
    text: "Checkout my new list!"
  })
}

export const Settings = ({title, random, publicz, onRelease,releasedCount,listSize, listId}) => {
    const { origin } = window.location;
    const history = useHistory();
    const showShareButton = navigator.share
    const settings = [
      {
        id: "random",
        title: "Randomized Order",
        onChange: random.onChange,
        enabled: random.enabled,
        checked: random.checked,
        info: `Makes each player of your list get a random order of alternatives.
               To allow this you will need to release all items at once.`
      },
      {
        id: "public",
        title: "Publicly Available",
        onChange: publicz.onChange,
        enabled: publicz.enabled,
        checked: publicz.checked,
        info: `This makes your list public to the world.
               Every one can then find it by searching for its title.`
      }
    ]

    const releaseButton = random.checked ?
        <RandomReleaseButton disabled={releasedCount >= listSize} onReleaseAll={() => onRelease(listSize-1)}/> :
        <OrderedReleaseButton released={releasedCount} max={listSize} onRelease={() => onRelease(releasedCount + 1)}/>
    return (
        <div className={style.body}>
            <div className={style.settingsCard}>
                <SettingsAccordian settings={settings} />
                {releaseButton}
                <div className={style.statsAndShare}>
                  <Button id={style.stats} variant="outlined" onClick={() => history.push(`/${listId}/dashboard`)}>Stats</Button>
                  {showShareButton && <Button id={style.share} variant="outlined" onClick={shareUrl(title, `${origin}/${listId}/`)}>
                      Share <ShareIcon />
                  </Button>}
                </div>
            </div>
        </div>
    )
}