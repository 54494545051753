import React, { useEffect, useState } from "react";
import { Button, TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Done } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styles from "./ListPicker.module.css";
import { useDebounce } from "../lib/hooks";
import lists from "../lib/lists";

function searchingAdornment(isSearching, done) {
  return (
    <InputAdornment position="end">
      {done ? <Done /> : isSearching && <CircularProgress />}
    </InputAdornment>
  );
}

const ListPicker = ({ onJoinClick, onDashboardClick }) => {
  const [query, setQuery] = useState("");
  const debauncedQuery = useDebounce(query, 500);
  const [list, setList] = useState(null);
  const [midResults, setMidResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    if (debauncedQuery) {
      setIsSearching(true);
      lists.search(debauncedQuery).then(
          result => {
            console.log(result)
            setMidResults(result);
            setIsSearching(false);
          }
      )
    }
  }, [debauncedQuery]);
  const listFound = list instanceof Object;
  return (
    <div className={styles.container}>
      <div>
        <Autocomplete
          options={midResults}
          freeSolo
          getOptionLabel={l => l.title || l}
          renderInput={(params =>
              <TextField
                  {...params}
                  className={styles.text}
                  label="List code"
                  InputProps={{
                    ...params.InputProps,
                   endAdornment: (
                       <>
                         {searchingAdornment(isSearching, listFound)}
                         {params.InputProps.endAdornment}
                       </>
                   )
                  }}
                  variant="outlined" />)}
          value={list}
          onChange={(e, newValue) => setList(newValue)}
          inputValue={query}
          onInputChange={(event, newValue) => setQuery(newValue)}
        />
      </div>
      <div className={styles.buttons}>
        <Button
          className={styles.dashboardButton}
          variant="outlined"
          color="primary"
          disabled={!listFound}
          onClick={() => onDashboardClick(list)}
        >
          Stats
        </Button>
        <Button
          className={styles.joinButton}
          variant="contained"
          color="primary"
          disabled={!listFound}
          onClick={() => onJoinClick(list)}>
          Join!
        </Button>
      </div>
    </div>
  );
};

export default ListPicker;
