import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import lists from "../lib/lists";
import PasswordField from "../components/PasswordField";
import styles from "./Create.module.css";

const Create = ({ history }) => {
  const [title, setTitle] = useState("");

  const createList = () => {
    if (title === "root") {
      history.push("/root");
      return;
    }
    lists.create(title).then(id => history.push(`/${id}/admin`));
  };

  const createIsEnabled = title.length >= 3;

  return (
    <div className={styles.create}>
      <div className={styles.input}>
        <TextField
          label="Title of your List"
          variant="filled"
          value={title}
          fullWidth
          onChange={e => setTitle(e.target.value)}
        />
      </div>
      <Button
        className={styles.button}
        variant="contained"
        color="secondary"
        disabled={!createIsEnabled}
        onClick={createList}
      >
        Create
      </Button>
    </div>
  );
};

export default withRouter(Create);
