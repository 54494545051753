import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { useHistory, Redirect } from "react-router-dom";
import lists from "../lib/lists";
import styles from "./Root.module.css";
import { user } from "../lib/firebase";

const useSummaries = () => {
  const [summaries, setSummaries] = useState({});
  useEffect(() => lists.watchSummaries(
    async summary => {
      setSummaries(oldS => ({ ...oldS, [summary.id]: summary }));
    },
  ),
  []);
  return Object.values(summaries);
};


const deleteList = id => lists.delete(id);

const Root = () => {
  const summaries = useSummaries();
  const history = useHistory();
  const allowed = user != null && (user.displayName === "David Tennander" || user.displayName === "Aiman Josefsson");
  return (
    <div className={styles.board}>
      {allowed || <Redirect to="/login" /> }
      {summaries.map(s => (
        <div className={styles.card}>
          <div className={styles.info}>
            <strong>Name:</strong> <span>{s.title}</span>
            <strong>Released:</strong> <span>{s.released}</span>
            <strong>Completed:</strong> <span>{s.completed ? "✅" : "🚫"}</span>
          </div>
          <div className={styles.buttons}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => history.push(`/${s.id}`)}
            >
              View
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => history.push(`/${s.id}/admin`)}
            >
              Admin
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                deleteList(s.id);
                history.push("/tmp");
                history.goBack();
              }}
            >
              delete
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Root;
