import React, { useEffect, useState } from "react";
import "./App.css";
import {BrowserRouter as Router,
  Route,
  Switch,
  useHistory} from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";

import { createMuiTheme } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Home from "./views/Home";
import Player from "./views/Player";
import Admin from "./views/Admin";
import Create from "./views/Create";
import Dashboard from "./views/Dashboard";
import Root from "./views/Root";
import { initFirebase } from "./lib/firebase";
import Login from "./views/Login";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#111D4A",
    },
    secondary: {
      main: "#92140C",
    },
    black: {
      main: "#1e1e24",
    },
    white: {
      main: "#fff8f0",
    },
    yellow: {
      main: "#ffcf99",
    },
  },
});

const AppRoutes = () => (
  <Switch>
    <Route path="/create">
      <Create />
    </Route>
    <Route path="/login">
      <Login />
    </Route>
    <Route path="/root">
      <Root />
    </Route>
    <Route path="/:id/admin">
      <Admin />
    </Route>
    <Route path="/:id/dashboard">
      <Dashboard />
    </Route>
    <Route path="/:id">
      <Player />
    </Route>
    <Route path="/">
      <Home />
    </Route>
  </Switch>
);

function useFirebase() {
  const [initialized, setInit] = useState(false);
  useEffect(() => {
    initFirebase().then(() => setInit(true));
  }, []);
  return initialized;
}

function App() {
  const initialized = useFirebase();
  const history = useHistory();
  return (
    <div id="app">
      <ThemeProvider theme={theme}>
        <header style={{
          background: theme.palette.secondary.main,
          padding: 5
        }}>
          <h1>
            <div
              role="button"
              tabIndex={-1}
              style={{ color: theme.palette.secondary.contrastText }}
              onClick={() => {
                history.push("/");
                history.push("/lol");
                history.goBack();
              }}
              onKeyDown={() => {}}
            >
              A Top 10 Game
            </div>
          </h1>
        </header>
        <div id="content" style={initialized ? {} : { display: "flex" }}>
          {initialized ? (
            <AppRoutes />
          ) : (
            <CircularProgress
              style={{
                margin: "auto",
                height: "10vw",
                maxHeight: "60px",
                width: "10vw",
                maxWidth: "60px",
              }}
              color="secondary"
            />
          )}
        </div>
      </ThemeProvider>
    </div>
  );
}

export default () => (
  <Router>
    <App />
  </Router>
);
