import React from "react";

const Logo = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width={width}
    height={height}
    fill="currentcolor"
  >
    <path
      d="M22 62
    A31.5 31.5 0 0 0 42 62
    L42 22
    L62 22
    A31.5 31.5 0 0 0 2 22
    L22 22
    Z"
    />
    <circle cx="26" cy="25" r="1" fill="#fff8f0" />
    <line
      x1="30"
      x2="38"
      y1="25"
      y2="25"
      stroke="#fff8f0"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <circle cx="26" cy="33" r="1" fill="#fff8f0" />
    <line
      x1="30"
      x2="38"
      y1="33"
      y2="33"
      stroke="#fff8f0"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <circle cx="26" cy="41" r="1" fill="#fff8f0" />
    <line
      x1="30"
      x2="38"
      y1="41"
      y2="41"
      stroke="#fff8f0"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <circle cx="26" cy="49" r="1" fill="#fff8f0" />
    <line
      x1="30"
      x2="38"
      y1="49"
      y2="49"
      stroke="#fff8f0"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <circle cx="26" cy="57" r="1" fill="#fff8f0" />
    <line
      x1="30"
      x2="38"
      y1="57"
      y2="57"
      stroke="#fff8f0"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default Logo;
