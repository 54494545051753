import { initializeApp, firestore, auth, functions as firebaseFunc } from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCJTCTCfxtsoIGyhW3Q8WjwRU-8johMOuM",
  authDomain: "staging-topr.firebaseapp.com",
  databaseURL: "https://staging-topr.firebaseio.com",
  projectId: "staging-topr",
  storageBucket: "staging-topr.appspot.com",
  messagingSenderId: "625785527086",
  appId: "1:625785527086:web:b57f3e1fc2eb5c97b2f8bf",
};

// eslint-disable-next-line import/no-mutable-exports
export let db = null;
// eslint-disable-next-line import/no-mutable-exports
export let timestamp = null;
// eslint-disable-next-line import/no-mutable-exports
export let user = null;
export let functions = null;

export async function initFirebase() {
  var config;
  try {
    const resp = await fetch("/__/firebase/init.json");
    config = await resp.json();
  } catch (e) {
    config = firebaseConfig;
  }
  initializeApp(config);
  const databaseRef = firestore();
  db = databaseRef.collection("lists");
  timestamp = firestore.FieldValue.serverTimestamp();
  functions = firebaseFunc();
}
// eslint-disable-next-line import/prefer-default-export
export const initialized = () => db != null && timestamp != null;

export const isLoggedIn = () => user != null;

export const loginWithGoogle = async () => {
  const provider = new auth.GoogleAuthProvider();
  const result = await auth().signInWithPopup(provider);
  user = result.user;
};
