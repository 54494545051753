import {db, functions, timestamp} from "./firebase";


async function generateId(listsDb) {
  const id = Math.random().toString(34).substring(2, 8).toUpperCase();
  const { exists } = await db.doc(id).get();
  if (exists) {
    return generateId(listsDb);
  }
  return id;
}

const lists = {
  watchSummaries: onList =>
    db.onSnapshot(s =>
      s.docs
        .map(d => d.data())
        .map(list => ({
          id: list.id,
          title: list.title,
          completed: list.completed,
          released: list.released,
        }))
        .forEach(onList)
    ),
  create: async title => {
    const id = await generateId();
    await db.doc(id).set({
      id,
      title,
      password: "NYI",
      completed: false,
      released: 0,
      entries: ["", "", "", "", "", "", "", "", "", ""],
      public: false,
      random: false,
    });
    return id;
  },
  get: async id => {
    const list = await db.doc(id).get();
    return list.data();
  },
  getResults: async id => {
    return await db.doc(id).collection("results").get();
    // return db.doc(id).collection("results");
  },
  delete: id => db.doc(id).delete(),
  setCompleted: async (id, completed) => db.doc(id).update({ completed }),
  setEntries: async (id, entries) => db.doc(id).update({ entries }),
  setReleased: async (id, released) => db.doc(id).update({ released }),
  setRandom: async (id, random) => db.doc(id).update({ random }),
  listener: id => db.doc(id),
  saveResult: async (id, result) => {
    const data = {
      result,
      timestamp,
    };
    return db.doc(id).collection("results").add(data);
  },
  setPublic: async (id, pub) => db.doc(id).update({ public: pub }),
  search: query => functions.httpsCallable("search")({query}).then(r => r.data)
};

export default lists;
