/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { List, ListItem, ListItemText, useTheme } from "@material-ui/core";
import RootRef from "@material-ui/core/RootRef";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import styles from "./DraggableList.module.css";

const DraggableList = ({ list, setList }) => {
  const reorder = (items, startIndex, endIndex) => {
    const result = Array.from(items);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const theme = useTheme();

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      list,
      result.source.index,
      result.destination.index
    ).map(({ text }) => text);
    setList(items);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <RootRef rootRef={provided.innerRef}>
            <List className={styles.list}>
              {list.map((item, index) => (
                <span key={item.key} style={{ display: "flex" }}>
                  {item.completed ? (
                    <ListItem
                      className={`${styles.listItem} ${
                        item.released && styles.released
                      }`}
                      style={{
                        boxShadow: "none",
                        border: `1px solid ${theme.palette.black.main}`,
                      }}
                      ContainerComponent="li"
                    >
                      <ListItemText primary={item.component} />
                    </ListItem>
                  ) : (
                    <Draggable
                      draggableId={`${item.text}-${item.key}`}
                      index={index}
                    >
                      {provided => (
                        <RootRef rootRef={provided.innerRef}>
                          <ListItem
                            className={styles.listItem}
                            ContainerComponent="li"
                            {...provided.draggableProps}
                            // Dom jävlarna försöker enforca tabindex.... Vi fixar't!
                            {...{ ...provided.dragHandleProps, tabIndex: -1 }}
                          >
                            <DragIndicatorIcon
                              style={{ opacity: 0 }}
                              color="disabled"
                              className={styles.dragger}
                            />
                            <ListItemText primary={item.component} />
                            <DragIndicatorIcon
                              color="disabled"
                              className={styles.dragger}
                            />
                          </ListItem>
                        </RootRef>
                      )}
                    </Draggable>
                  )}
                </span>
              ))}
              {provided.placeholder}
            </List>
          </RootRef>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
