import { TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useState } from "react";

function eyeAdornment(setShowPassword, showPassword) {
  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => setShowPassword(!showPassword)}
        edge="end"
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
}

const PasswordField = ({ password, setPassword , fullWidth = false}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <TextField
      label="Password"
      variant="filled"
      value={password}
      fullWidth = {fullWidth}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: eyeAdornment(setShowPassword, showPassword)
      }}
      onChange={e => setPassword(e.target.value)}
    />
  );
};

export default PasswordField;
