import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { schemeCategory10 } from "d3-scale-chromatic";
import { scaleLinear } from "d3-scale";
import lists from "../lib/lists";

const Dashboard = () => {
  const match = useRouteMatch();
  const { id } = match.params;
  const [data, setData] = useState([]);
  const [hasPersonal, setHasPersonal] = useState(false);
  const [sortMethod, setSortMethod] = useState("chronologic");
  const [filters, setFilters] = useState({
    mean: true,
    all: false,
    personal: false,
  });

  const scaleX = scaleLinear().domain([1, 10]).range([30, 110]);
  const scaleY = scaleLinear().domain([0, 10]).range([15, 100]);

  const width = window.innerWidth;

  useEffect(() => {
    lists.get(id).then(({ entries }) => {
      lists.getResults(id).then(res => {
        const table = res.docs
          .map(doc => doc.data())
          .map(x => x.result.map(r => r.value));
        const scoreBoard = table.reduce(
          (acc, next) =>
            acc.map(({ entry, points }) => ({
              entry,
              points: [...points, next.indexOf(entry) + 1],
            })),
          entries.map(entry => ({ entry, points: [] }))
        );
        const finalData = scoreBoard.map(({ entry, points }, index) => {
          const sortedPoints = points
            .filter(p => p > 0 && p <= 10)
            .sort((a, b) => a - b);
          const { length } = sortedPoints;
          const min = sortedPoints[0];
          const max = sortedPoints[length - 1];
          const mean =
            sortedPoints.reduce((acc, next) => acc + next, 0) / length;
          return { entry, min, max, mean, points: sortedPoints, index };
        });
        setData(finalData);
      });
    });
  }, [id]);

  useEffect(() => {
    if (
      data.length > 0 &&
      data[0].personal === undefined &&
      localStorage.getItem(`answers_${id}`)
    ) {
      const personalData = JSON.parse(localStorage.getItem(`answers_${id}`));
      if (personalData.every(p => p.value !== "")) {
        const aggregated = data.map(d => {
          const { index } = personalData.find(p => d.entry === p.value);
          return { ...d, personal: index + 1 };
        });
        setData(aggregated);
        setHasPersonal(true);
      }
    }
  }, [id, data]);

  if (data.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70%",
        }}
      >
        <CircularProgress size={70} />
      </div>
    );
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <h3>Statistics</h3>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          fontSize: 10,
          marginBottom: 16,
        }}
      >
        {data.map(({ entry }, i) => (
          <span
            key={entry}
            style={{ color: schemeCategory10[i], paddingRight: 8 }}
          >
            {entry}
          </span>
        ))}
      </div>
      <svg width={width < 620 ? width - 20 : 600} viewBox="0 0 120 100">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(n => (
          <g key={n} style={{ transform: `translateX(${scaleX(n)}px)` }}>
            <text
              style={{
                fontSize: 4,
                transform: "translateY(5px)",
              }}
              textAnchor="middle"
            >
              {n}
            </text>
            <line
              x1={0}
              x2={0}
              y1={scaleY(-1)}
              y2={scaleY(10)}
              stroke="rgba(0,0,0,0.25)"
              strokeWidth="0.5"
            />
          </g>
        ))}
        {data.map(({ entry, mean, min, max, points, personal }, i) => {
          return (
            <g key={entry} style={{ transform: `translateY(${scaleY(i)}px)` }}>
              {/* <line
                x1={scaleX(min)}
                x2={scaleX(min)}
                y1={-1.5}
                y2={1.5}
                strokeWidth={1}
                stroke={schemeCategory10[i]}
              />
              <line
                x1={scaleX(max)}
                x2={scaleX(max)}
                y1={-1.5}
                y2={1.5}
                strokeWidth={1}
                stroke={schemeCategory10[i]}
              /> */}
              <text
                style={{
                  fontSize: 4,
                  transform: "translateY(1px)",
                }}
                fill={schemeCategory10[i]}
              >
                {entry.length > 10 ? `${entry.substring(0, 8)}...` : entry}
              </text>
              {filters.all &&
                points.map((p, pIndex) => (
                  <circle
                    key={`${p}-${pIndex}`}
                    cx={scaleX(p)}
                    cy={0}
                    r={1}
                    fill="rgba(0,0,0,0.2)"
                  />
                ))}
              {filters.mean && (
                <circle
                  cx={scaleX(mean)}
                  cy={0}
                  r={2}
                  stroke={schemeCategory10[i]}
                  fill="transparent"
                />
              )}
              {filters.personal && (
                <circle
                  cx={scaleX(personal)}
                  cy={0}
                  r={1.5}
                  fill={schemeCategory10[i]}
                />
              )}
            </g>
          );
        })}
      </svg>

      <br />

      <FormControl component="fieldset">
        <FormLabel style={{ textAlign: "center" }} component="legend">
          Sort by:
        </FormLabel>
        <RadioGroup
          aria-label="sort"
          name="sort"
          value={sortMethod}
          row
          onChange={e => {
            const { value } = e.target;
            setSortMethod(value);
            switch (value) {
              case "chronologic":
                setData(data.sort((a, b) => a.index - b.index));
                break;
              case "mean":
                setData(data.sort((a, b) => a.mean - b.mean));
                break;
              case "personal":
                setData(data.sort((a, b) => a.personal - b.personal));
                break;
              default:
            }
          }}
        >
          <FormControlLabel
            value="chronologic"
            control={<Radio />}
            label="Order"
          />
          <FormControlLabel value="mean" control={<Radio />} label="Mean" />
          {hasPersonal && (
            <FormControlLabel
              value="personal"
              control={<Radio />}
              label="Personal"
            />
          )}
        </RadioGroup>
      </FormControl>

      <FormControl component="fieldset">
        <FormLabel style={{ textAlign: "center" }} component="legend">
          Filter:
        </FormLabel>
        <FormGroup
          row
          onChange={e => {
            const { name } = e.target;
            setFilters({ ...filters, [name]: !filters[name] });
          }}
        >
          <FormControlLabel
            control={<Checkbox checked={filters.mean} name="mean" />}
            label="Mean"
          />
          <FormControlLabel
            control={<Checkbox checked={filters.all} name="all" />}
            label="All"
          />
          {hasPersonal && (
            <FormControlLabel
              control={<Checkbox checked={filters.personal} name="personal" />}
              label="Personal"
            />
          )}
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default Dashboard;
