import React from "react";

const Arrow = ({ style, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width="64"
    height="64"
    fill="currentcolor"
    style={style}
    onClick={onClick}
  >
    <path d="M8 48 L16 48 L32 22 L48 48 L56 48 L32 10 Z" />
  </svg>
);

export default Arrow;
