import React from "react";
import Fab from "@material-ui/core/Fab";
import Zoom from "@material-ui/core/Zoom";
import {useTheme} from "@material-ui/core";

export const ToggleButton = ({A, B, showA, onNewValue}) => {

  const onClick = () => onNewValue(showA);
  const theme = useTheme();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
      <div>
        <Zoom in={showA}  unmountOnExit timeout={transitionDuration} style={{
          position: "absolute",
          transitionDelay: `${showA ? transitionDuration.exit : 0}ms`,
        }}>
          <Fab color={"primary"} onClick={onClick}>
            {A}
          </Fab>
        </Zoom>
        <Zoom in={!showA} timeout={transitionDuration} style={{
          transitionDelay: `${!showA ? transitionDuration.exit : 0}ms`,
        }}>
          <Fab color={"secondary"} onClick={onClick}>
            {B}
          </Fab>
        </Zoom>
      </div>
  )
}